import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import type { IViewProps } from 'common/types/views';
import type { WithChildren } from 'common/types';

import { useGenericFieldsActionsAdopted, useGenericFieldsDataAdopted } from 'containers/FormDataAdapter';
import { determineAndApplyErrorToForm } from 'utils/determineAndApplyErrorToForm';

import FormWrapper from 'common/components/FormWrapper';
import ViewsButtonsBlock from 'common/components/ViewsButtonsBlock';
import { IFormValues } from 'common/components/FormHF/types';
import { prepareDefaultFormData } from 'common/components/FormHF/utils';
import { FormLoader } from 'common/components/Loader';


import TosCheckboxConnected from 'views/SummaryView/components/TosCheckboxConnected';
import { LengthType } from 'common/types/additional';

import {
  STEP_ACCESSORIES,
  STEP_ANGLE,
  STEP_ASS_LENGTH,
  STEP_FITTING,
  STEP_HOSE,
} from 'containers/FormSwitcher/stepNames';
import styles from './styles.module.scss';
import { PrintSection } from './PrintSection';
import { useSummary } from './useSummary';
import { Item } from './Item';
import { scrollToEmptyInput } from '../../utils/scrollToInput';


const SummaryView = ({
  onNextClickHandler,
  onBackClickHandler,
  onResetHandler,
  title,
  description,
  fieldsNames,
  onStepChange,
  children,
  isNextDisabled,
  isLoading: isCartLoading,
}: WithChildren<IViewProps>) => {
  const idForm = 'form-accessories';
  const { error, isLoading } = useGenericFieldsDataAdopted();
  const { getFieldsByName } = useGenericFieldsActionsAdopted();

  const initialFields = useMemo(() => getFieldsByName(fieldsNames), [getFieldsByName, fieldsNames]);

  const defaultValues = prepareDefaultFormData(initialFields);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    formState: { errors },
    setError,
  } = useForm<IFormValues>({ defaultValues });

  const tosAgreed = watch('tosAgreed');

  useEffect(() => {
    if (tosAgreed) {
      scrollToEmptyInput();
    }
  }, [tosAgreed]);


  // eslint-disable-next-line no-unused-vars
  const onSubmitSuccess: SubmitHandler<IFormValues> = useCallback((data) => {
    if (tosAgreed) {
      onNextClickHandler();
    } else {
      setError('tosAgreed', { message: 'Please accept these terms' });
    }
  }, [onNextClickHandler, tosAgreed]);

  useEffect(() => {
    if (error) {
      determineAndApplyErrorToForm(error, setError);
    }
  }, [error, setError]);

  const summary = useSummary();
  const {
    hose,
    fitting1,
    lengthInInches,
    lengthInFeet,
    lengthType,
    angle,
    accessories,
    customerPartNumber,
    testing,
    fitting2,
    quantity,
  } = summary;

  return (
    <FormWrapper
      header={title}
      description={description}
    >
      <form
        id={idForm}
        onSubmit={handleSubmit(onSubmitSuccess)}
        className="print"
      >
        {(isLoading || isCartLoading) && <FormLoader />}
        <div className={styles.itemList}>
          <Item
            onClick={() => onStepChange(STEP_HOSE)}
            label="Hose Selection"
            title={hose?.title}
            image={hose?.attributes?.image}
            description={hose?.attributes?.description}
          />
          <Item
            onClick={() => onStepChange(STEP_FITTING)}
            label="Fitting 1"
            title={fitting1?.title}
            image={fitting1?.attributes?.image}
            description={fitting1?.attributes?.description}
          />
          <Item
            onClick={() => onStepChange(STEP_FITTING)}
            label="Fitting 2"
            title={fitting2?.title}
            image={fitting2?.attributes?.image}
            description={fitting2?.attributes?.description}
          />
          <Item
            onClick={() => onStepChange(STEP_ASS_LENGTH)}
            label="Overall Assembly Length"
            title={lengthType === LengthType.feet ? (`${lengthInFeet} feet`) : (`${lengthInInches} inches`)}
          />
          {(typeof angle !== 'undefined') && (
            <Item
              onClick={() => onStepChange(STEP_ANGLE)}
              label="Angle of Rotation"
              title={`${angle}°`}
            />
          )}
          {accessories && (
            <Item
              onClick={() => onStepChange(STEP_ACCESSORIES)}
              label="Accessories"
              title={accessories.title}
              image={accessories.attributes?.image}
              description={accessories.attributes?.description}
            />
          )}
          {customerPartNumber && (
            <Item
              onClick={() => onStepChange(STEP_ACCESSORIES)}
              label="Customer Part Number"
              title={customerPartNumber}
            />
          )}
          {testing && (
            <Item
              onClick={() => onStepChange(STEP_ACCESSORIES)}
              label="Pressure Test and Certify Assembly"
              title={testing ? (
                <>
                  <svg
                    width="15"
                    height="12"
                    viewBox="0 0 15 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {/* eslint-disable-next-line */}
                  <path d="M12.6684 0.382523C12.9276 0.134418 13.2725 -0.00274376 13.6304 -2.12689e-05C13.9883 0.00270122 14.3311 0.145096 14.5865 0.397115C14.842 0.649135 14.9901 0.991068 14.9995 1.35076C15.009 1.71046 14.8791 2.05978 14.6372 2.32502L7.29535 11.5565C7.16911 11.6932 7.01674 11.8029 6.84735 11.8791C6.67797 11.9552 6.49505 11.9962 6.30954 11.9997C6.12402 12.0032 5.93972 11.969 5.76764 11.8992C5.59557 11.8294 5.43927 11.7254 5.30807 11.5935L0.439241 6.6984C0.303652 6.57138 0.194899 6.4182 0.119471 6.248C0.0440431 6.0778 0.00348419 5.89407 0.000214773 5.70777C-0.00305464 5.52147 0.0310325 5.33642 0.100442 5.16365C0.169851 4.99088 0.273161 4.83394 0.404209 4.70219C0.535257 4.57043 0.691357 4.46657 0.863198 4.39678C1.03504 4.327 1.2191 4.29273 1.4044 4.29602C1.5897 4.2993 1.77245 4.34008 1.94173 4.41591C2.11102 4.49175 2.26337 4.60109 2.38972 4.73741L6.24283 8.60944L12.6334 0.423223C12.6449 0.40898 12.6572 0.395392 12.6702 0.382523H12.6684Z" fill="#2BB483" />
                  </svg>
                  <div>Performed to industry standards</div>
                </>
              ) : <div>Not performed</div>}
            />
          )}
          <Item
            onClick={() => onStepChange(STEP_ACCESSORIES)}
            label="Assembly Qty"
            title={`${quantity}`}
          />
        </div>
        <div className={styles.tos}>
          <TosCheckboxConnected control={control} watch={watch} setValue={setValue} />
        </div>
        {children}
      </form>
      <div className={styles.desktop}>
        <ViewsButtonsBlock
          idForm={idForm}
          onResetClickHandler={onResetHandler}
          onBackClickHandler={onBackClickHandler}
          nextButtonText="Complete My Assembly"
          isNextDisabled={isNextDisabled || !tosAgreed}
        />
      </div>
      <PrintSection summary={summary} />
      <div className={styles.mobile}>
        <ViewsButtonsBlock
          idForm={idForm}
          onResetClickHandler={onResetHandler}
          onBackClickHandler={onBackClickHandler}
          nextButtonText="Complete My Assembly"
          isNextDisabled={isNextDisabled || !tosAgreed}
        />
      </div>
    </FormWrapper>
  );
};

// @ts-ignore
export default SummaryView;
