import React, { useState } from 'react';

export const Item = ({
  title,
  onClick,
  image,
  label,
  className,
  description,
}:
        {
            title?: string | React.ReactNode,
            className?: string,
            onClick: () => void,
            image?: string,
            description?: string,
            label: string
        }) => {
  const [open, setOpen] = useState<boolean>(false);
  const toggle = () => setOpen((o) => !o);

  return (
    <div className={`item ${className}`}>
      {/* eslint-disable-next-line */}
        <a onClick={onClick}>{label}</a>
      <div>
        {image && <img src={image} alt="" />}
        <div>{title}</div>
        {description && (
          !open
            ? <button type="button" onClick={toggle}>Show details</button>
            : <button type="button" onClick={toggle}>Hide details</button>
        )}
      </div>
      {open && description && <div className="description">{description}</div>}
    </div>
  );
};
